import React from 'react'
import { match } from '@klickmarketing/react-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

export default function AboutImage() {
  const { aboutIDX } = useStaticQuery(graphql`
    {
      aboutIDX: file(relativePath: { eq: "About/AboutIDX1.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
            breakpoints: [630, 900, 1350, 1920]
            sizes: "(min-width: 1280px) 1230px, 100vw"
          )
        }
      }
    }
  `)
  return <Image role="presentation" alt="" image={getImage(aboutIDX)} />
}

const Image = styled(GatsbyImage)`
  width: 100%;
  position: relative;

  ${match.isMD} {
    width: calc(50% - 100px);
    position: absolute;
    right: 0px;
    top: 0px;
  }
`
