import React from 'react'
import {
  Button,
  Heading,
  isBrowser,
  Link,
  match,
  P,
  Section,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core'
import { graphql } from 'gatsby'
import {
  Link as I18nLink,
  Trans,
  useI18next,
} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import AboutImage from '../components/AboutImage/AboutImage'
import Layout from '../components/Layout/Layout'
import { useAuth0 } from '../hooks/useAuth0Klick'

const LockIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="44"
    viewBox="0 0 35 44"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.4919 21.674C34.4919 16.909 34.2769 16.695 29.5509 16.535C29.5509 14.447 29.5509 11.77 29.4979 10.11C29.2289 3.89999 25.3629 0.151967 19.0799 -0.0090332H15.8569C8.92989 0.0449668 4.95689 2.83297 5.00989 9.68597C5.00989 11.667 5.5999 12.577 7.8019 12.524C9.7889 12.47 10.9709 12.256 10.9169 9.79297C10.8629 5.88497 13.0649 5.71997 17.7909 5.82697C21.7109 5.88097 23.6439 7.75497 23.6979 11.449V16.535C17.0929 16.588 10.4869 16.588 3.8279 16.642C0.766901 16.642 0.0688981 17.391 0.0688981 20.442C0.0688981 26.974 0.122897 33.452 0.0148972 39.983C-0.0381028 42.499 0.981894 43.624 3.45189 43.624H31.1089C33.4179 43.624 34.4919 42.607 34.4919 40.197C34.4379 33.988 34.4919 27.831 34.4919 21.674ZM19.1919 31.151V34.202C19.1806 34.6929 18.9776 35.16 18.6263 35.5032C18.2751 35.8465 17.8035 36.0386 17.3124 36.0386C16.8213 36.0386 16.3497 35.8465 15.9984 35.5032C15.6472 35.16 15.4442 34.6929 15.4329 34.202V31.151C14.6963 30.8144 14.0639 30.2859 13.6018 29.6208C13.1398 28.9558 12.8651 28.1787 12.8067 27.371C12.7483 26.5633 12.9082 25.7547 13.2698 25.03C13.6313 24.3054 14.1811 23.6914 14.8616 23.2523C15.5421 22.8133 16.3281 22.5653 17.1373 22.5345C17.9466 22.5038 18.7492 22.6913 19.4611 23.0774C20.1729 23.4635 20.7678 24.0339 21.1833 24.729C21.5989 25.4241 21.8198 26.2182 21.8229 27.028C21.8332 27.8974 21.5892 28.7508 21.1209 29.4833C20.6525 30.2159 19.9803 30.7955 19.1869 31.151H19.1919Z"
    />
  </svg>
)

const BlockIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00903419 -0.00597843H16.2441V16.243H0.00903419V-0.00597843ZM8.12708 18.952C9.73439 18.9506 11.306 19.426 12.6431 20.318C13.9802 21.21 15.0227 22.4785 15.6387 23.9631C16.2547 25.4477 16.4165 27.0816 16.1037 28.6582C15.7909 30.2348 15.0175 31.6832 13.8814 32.8201C12.7453 33.9571 11.2975 34.7315 9.72113 35.0455C8.14478 35.3594 6.51074 35.1988 5.0257 34.5839C3.54066 33.969 2.27133 32.9274 1.37836 31.591C0.485382 30.2546 0.00883696 28.6833 0.00903419 27.076C0.00850879 26.0095 0.218087 24.9533 0.625794 23.9678C1.0335 22.9823 1.63134 22.0868 2.38519 21.3324C3.13905 20.578 4.03416 19.9795 5.01935 19.571C6.00454 19.1626 7.06057 18.9522 8.12708 18.952ZM18.9501 18.952H35.188V35.201H18.9501V18.952ZM27.0681 -0.00597843C28.6753 -0.00736315 30.2469 0.467983 31.584 1.35993C32.9211 2.25187 33.9636 3.5203 34.5796 5.00482C35.1956 6.48935 35.3575 8.12328 35.0448 9.69983C34.7321 11.2764 33.9588 12.7248 32.8228 13.8618C31.6867 14.9988 30.239 15.7733 28.6627 16.0873C27.0864 16.4014 25.4524 16.2409 23.9673 15.6262C22.4823 15.0114 21.2129 13.97 20.3198 12.6337C19.4267 11.2974 18.9501 9.72629 18.9501 8.11902C18.9496 7.05247 19.1591 5.99624 19.5668 5.01068C19.9745 4.02513 20.5723 3.12955 21.3262 2.37506C22.08 1.62057 22.9751 1.02195 23.9603 0.613406C24.9455 0.204861 26.0015 -0.00558456 27.0681 -0.00597843Z"
      fill="black"
    />
  </svg>
)

const AboutPage = () => {
  const { t } = useI18next()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const returnTo = isBrowser && window.location.pathname
  const signUpOnClick = () =>
    loginWithRedirect({
      initialScreen: 'signUp',
      appState: {
        returnTo,
      },
    })

  return (
    <Layout
      seoProps={{
        title: t('About IDX', { ns: 'about' }),
        description: t(
          'Klick Ideas Exchange is our way of sharing our vision with you. Explore hours of video content featuring some of the leading visionaries of our age.',
          { ns: 'about' }
        ),
      }}
    >
      <ThemeProvider themeType="onWhite">
        <StyledSection name="About IDX" mt={isDesktop ? 10 : 0}>
          <Container maxWidth="lg">
            <ContentBox py={4} textAlign={isDesktop ? 'left' : 'center'}>
              <Heading component="h1" variant={isDesktop ? 'h4' : 'h2'}>
                {t('About IDX', { ns: 'about' })}
              </Heading>
            </ContentBox>
          </Container>
          <AboutImage />
          <Container maxWidth="lg">
            <ContentBox pt={isDesktop ? 0 : 4}>
              <P variant="blurb2">
                {t(
                  'Your next big idea is waiting. Klick is constantly striving to push the healthcare industry forward. We believe that innovation is collaboration, and Klick Ideas Exchange (IDX) is our way of sharing that vision with you. We’ve curated countless hours of exclusive video content featuring some of the leading visionaries of our age, including Dr. Katrine Bosley, Leroy Hood, Dr. Eric Topol, Olivia Hallisey, and President Bill Clinton.',
                  { ns: 'about' }
                )}
              </P>
              <P variant="blurb2">
                {t(
                  'Each video is supported by a rich scaffolding of resources, including full transcripts, closed captioning, and instantly accessible references. You’ll also gain access to insights from industry experts specific to the content you’re watching, while you’re watching. Klick IDX allows you to tailor your viewing experience to suit your precise needs.',
                  { ns: 'about' }
                )}
              </P>
              <Box mt={8}>
                {!isAuthenticated && (
                  <StyledButton
                    startIcon={LockIcon}
                    size="large"
                    component={Link}
                    onClick={signUpOnClick}
                    $isWhite
                  >
                    <Trans ns="about">
                      Become an
                      <br />
                      exclusive member
                    </Trans>
                  </StyledButton>
                )}
                <StyledButton
                  startIcon={BlockIcon}
                  size="large"
                  component={I18nLink}
                  to="/discover"
                  $isLoggedIn={isAuthenticated}
                  {...(isAuthenticated
                    ? { color: 'primary' }
                    : { color: 'secondary', variant: 'outlined' })}
                >
                  <Trans ns="about">Start Discovering</Trans>
                </StyledButton>
              </Box>
            </ContentBox>
          </Container>
        </StyledSection>
      </ThemeProvider>
    </Layout>
  )
}

const StyledSection = styled(Section)`
  position: relative;
  overflow-y: hidden;
`

const ContentBox = styled(Box)`
  width: 100%;

  ${match.isMD} {
    width: 50%;
  }
`

const StyledButton = styled(Button)`
  min-height: 100px;
  margin-right: 16px;
  margin-bottom: 16px;
  width: 100%;

  svg {
    margin-right: 16px;

    path {
      fill: ${({ $isLoggedIn, $isWhite }) =>
        $isLoggedIn || $isWhite ? '#fff' : '#000'};
    }
  }

  ${match.isSM} {
    width: auto;
  }
`

export default AboutPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "about"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
